// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#modalHeader {
  font-family: Roboto;
  /* font-size: 20px; */
  font-weight: 700;
  /* line-height: 23.44px; */
  /* letter-spacing: -0.02em; */
  text-align: left;
  color: #000000;
}
`, "",{"version":3,"sources":["webpack://./src/modals/modal-styles.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,gBAAgB;EAChB,0BAA0B;EAC1B,6BAA6B;EAC7B,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":["#modalHeader {\n  font-family: Roboto;\n  /* font-size: 20px; */\n  font-weight: 700;\n  /* line-height: 23.44px; */\n  /* letter-spacing: -0.02em; */\n  text-align: left;\n  color: #000000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
