import React, { ChangeEvent, forwardRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./modal-styles.css";
import { TextField } from "@mui/material";
import { error, success } from "helpers/notification";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  "@media (max-width: 600px)": {
    p: 2, // Reduce padding on smaller screens
    width: "95%", // Use a wider width on smaller screens
  },
};

type Props = {
  fullName: string;
  isOpen: boolean;
  toggleModal: () => void;
  updateAddress: () => void;
};

export const AddressConfirmationModal = (props: Props) => {
  const [lastName, setLastName] = useState("");

  const handleAddressLastNameConfirmation = (e: React.FormEvent) => {
    e.preventDefault();
    //check if the props.fullName contains the last name
    if (props.fullName.toLowerCase().includes(lastName.toLowerCase())) {
      props.updateAddress();
      props.toggleModal();
    } else {
      error("Last name does not match the full name");
    }
  };

  return (
    <div>
      <Modal
        id="modal-address-confirmation"
        open={props.isOpen}
        onClose={props.toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleAddressLastNameConfirmation}>
          <Box sx={style}>
            <p id="modalHeader">
              To finalize your update, please enter the last name of the
              recipient:
            </p>
            <p>
              <TextField
                fullWidth
                required
                id="lastName"
                label="Enter your last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </p>
            <p
              id="modalButtons"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 4,
              }}
            >
              <Button
                variant="outlined"
                size="large"
                onClick={(e) => props.toggleModal()}
              >
                Cancel
              </Button>
              <Button variant="contained" size="large" type="submit">
                Send Updates
              </Button>
            </p>
          </Box>
        </form>
      </Modal>
    </div>
  );
};
