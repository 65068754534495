const config = {
  API_URL: process.env.REACT_APP_API_BASE_URL,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_MAP_ID: process.env.REACT_APP_GFE_MAP_ID || "",
  PROJECT_ID: process.env.REACT_APP_PROJECT_ID || "",
  GOOGLE_ANALYTICS_TRACKING_ID:
    process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID,
  ENV: process.env.REACT_APP_ENV,
  TRACKING_URL: process.env.REACT_APP_TRACKING_URL || "",
};

export default config;
