import React from "react";
import BTLogo from "../assets/images/logo.png";

const Header: React.FC = () => {
  return (
    <div id="header-div">
      <div id="header-div-logo">
        <img src={BTLogo} height={40} alt="Better Trucks Logo" />
      </div>
    </div>
  );
};

export default Header;
