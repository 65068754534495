import React, { useCallback, useRef, useState, useEffect } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const options = {
  zoomControl: true,
  gestureHandling: "greedy",
  restriction: {
    latLngBounds: {
      north: 49.38,
      south: 24.52,
      west: -125.0,
      east: -66.95,
    },
    strictBounds: true,
  },
};

interface MapProps {
  initialCenter?: { lat: number; lng: number };
  onSelectLocation: (lat: number, lng: number) => void;
}

const MapPreview: React.FC<MapProps> = ({
  initialCenter,
  onSelectLocation,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
    libraries: ["places"],
  });

  const [center, setCenter] = useState<{ lat: number; lng: number }>(
    initialCenter || { lat: 34.0522, lng: -118.2437 }
  );
  const [marker, setMarker] = useState<{ lat: number; lng: number }>(center);

  const mapRef = useRef<google.maps.Map>();

  useEffect(() => {
    if (!initialCenter && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setMarker(pos);
          setCenter(pos);
        },
        () => {
          setMarker(center); // Use the default if geolocation fails
        }
      );
    }
  }, [initialCenter, center]); // Include initialCenter and center in the dependency array

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  const onMapClick = useCallback(
    (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setMarker({ lat, lng });
        onSelectLocation(lat, lng);
      }
    },
    [onSelectLocation]
  );

  const onMarkerDragEnd = useCallback(
    (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setMarker({ lat, lng });
        onSelectLocation(lat, lng);
      }
    },
    [onSelectLocation]
  );

  const handlePlaceSelect = (place: any) => {
    console.log("place   -  ", place);

    if (place && place.value && place.value.geometry) {
      const lat = place.value.geometry.location.lat();
      const lng = place.value.geometry.location.lng();
      setCenter({ lat, lng });
      setMarker({ lat, lng });
      onSelectLocation(lat, lng);
    }
  };

  if (loadError)
    return (
      <div>
        Error loading maps: {loadError.message} {loadError.name}
      </div>
    );
  if (!isLoaded || !center) return <div>Loading Maps...</div>;

  return (
    <div>
      {/* <GooglePlacesAutocomplete
        selectProps={{
          onChange: handlePlaceSelect,
          placeholder: "Search for places...",
        }}
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      /> */}
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={20} // Set an initial zoom level
        center={center}
        options={options}
        onClick={onMapClick}
        onLoad={onMapLoad}
      >
        {marker && (
          <MarkerF
            position={marker}
            draggable={true}
            onDragEnd={onMarkerDragEnd}
          />
        )}
      </GoogleMap>
    </div>
  );
};

export default MapPreview;
