import React from "react";

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div id="footer-div" style={{ backgroundColor: "white", padding: "10px" }}>
      <div id="header-div-logo">
        Copyright © {currentYear} Better Trucks WebTracking. All rights
        reserved.
      </div>
    </div>
  );
};

export default Footer;
