import axios from "axios";
import config from "../config";

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = config.API_URL;

export const HelpersService = {
  getStatesDropdown: () => {
    const fullUrl = `api/Helpers/StatesDropdown`;
    return axios.get(fullUrl);
  },
};

export type StatesDropdownResponse = {
  code: string;
  name: string;
};
