import axios from "axios";
import config from "../config";

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = config.API_URL;

export const AddressService = {
  getAddressById: (id: string) => {
    return axios.get(`api/Addresses/${id}`);
  },

  getParcelAddress: (btParcelId: string) => {
    const fullUrl = `api/Addresses/ByBtShipmentId/${btParcelId.trim()}`;
    return axios.get(fullUrl);
  },

  updateParcelAddress: (
    addressId: number,
    btShipmentId: string,
    updateWithLatAndLong: boolean,
    data: any
  ) => {
    return axios.put(
      `api/Addresses/${addressId}/${btShipmentId}?updateWithCoordinates=${updateWithLatAndLong}`,
      data
    );
  },
};

export type AddressResponse = {
  id: number;
  name: string;
  company: string;
  street1: string;
  street2: string;
  cityId: number;
  city: string;
  stateId: number;
  state: string;
  zip: string;
  countryId: number;
  country: string;
  latitude: number;
  longitude: number;
  phone: string;
  email: string;
  metadata: string;

  googleValidated?: boolean;
  googleScore?: number;
  googlePlaceId?: string;
  googleAddress: string;

  //delivery notes
  lastAddressNote: string;
};
