import React from "react";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import InfomrationIcon from "@mui/icons-material/Info";

const AddressWarning: React.FC = () => {
  return (
    <div
      id="address-error"
      style={{
        display: "flex",
        alignItems: "center",
        border: "1px solid #ed6c02",
        padding: "10px",
        backgroundColor: "white",
        color: "#721c24",
      }}
    >
      <span id="address-error-icon">
        <InfomrationIcon color="warning" />
      </span>
      <span>
        <b style={{ color: "black" }}>We could not find your parcel.</b> Please,
        double check the traking number and try again.
      </span>
    </div>
  );
};

export default AddressWarning;
